import React, { useState, useRef, useEffect } from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import Slider from "react-slick";

import arrowRightDark from "../../../../assets/images/arrow-left-dark.svg";
import arrowLeftDark from "../../../../assets/images/arrow-right-dark.svg";
import ReactPlayer from "react-player/lazy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import VideoModal from "../../../Common/VideoModal";

const Default = ({ fields, isRtl, params, isLowCarbon }) => {
  const sliderRef = useRef(null);
  const thumbsRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  // debugger;
  const [showModal, setShowModal] = useState(false);

  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: isRtl === "true" ? true : false,
  };

  const thumbsSetting = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 7,
    focusOnSelect: false,
    rtl: isRtl === "true" ? true : false,
    beforeChange: (current, next) => {
      console.log(current);
      setActiveItem(next);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  useEffect(() => {
    sliderRef && sliderRef?.current?.slickGoTo(activeItem);
    thumbsRef && thumbsRef?.current?.slickGoTo(activeItem);
    window.dataLayer = window.dataLayer || [];
  }, [activeItem, showModal]);

  const toPrev = () => {
    sliderRef && sliderRef.current.slickPrev();
  };

  const toNext = () => {
    sliderRef && sliderRef.current.slickNext();
  };
  const handleThumbnailClick = function (activeItem) {
    console.log(activeItem);
    setActiveItem(activeItem);
    sliderRef && sliderRef?.current?.slickGoTo(activeItem);
  };
  let dataLayerVideoAdded = false;
  const handlePlayVideo = function (videoUrl) {
    if (!dataLayerVideoAdded) {
      window.dataLayer.push({
        event: "video_played",
        video_url: videoUrl,
      });
      dataLayerVideoAdded = true;
    }
  };

  return (
    <div
      className={`bg-${params?.BackgroundColor || "taupe-400"} md:py-14 py-7`}
    >
      <div className="container ">
        <div className="flex md:items-center items-start gap-6 justify-between md:flex-row flex-col">
          <RichText
            field={fields?.Title}
            className="text-brand font-[500] text-[40px]"
          />
          {fields?.SelectMedia?.length > 1 && (
            <div className="flex rtl:flex-row-reverse items-center gap-4">
              <img
                src={arrowLeftDark}
                onClick={toPrev}
                className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
                alt=""
              />
              <img
                src={arrowRightDark}
                onClick={toNext}
                className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
                alt=""
              />
            </div>
          )}
        </div>
        <div className="mt-4 overflow-hidden">
          <Slider {...settings} arrows={false} ref={sliderRef}>
            {fields?.SelectMedia.map((item, index) => (
              <div
                key={`video-${index}`}
                dir={isRtl === "true" ? "rtl" : "ltr"}
              >
                {showModal && (
                  <VideoModal
                    video={item?.fields?.Video}
                    poster={item?.fields?.Image}
                    isIframe={item?.fields?.IsIframe?.value}
                    onClose={() => {
                      console.log("on Close");
                      setShowModal(false);
                    }}
                  />
                )}
                <div
                  className={`min-h-[530px]  bg-cover ${
                    isLowCarbon ? "bg-green-gradient" : ""
                  }`}
                  style={{
                    backgroundImage:
                      item?.fields?.Video?.value?.href &&
                      !isLowCarbon &&
                      isLowCarbon !== null
                        ? `url(${item?.fields?.Image?.value?.src})`
                        : "",
                  }}
                >
                  {item.fields?.Image?.value.src &&
                  !item?.fields?.Video?.value?.href ? (
                    <>
                      {!isLowCarbon && isLowCarbon !== null && (
                        <Image
                          field={item.fields?.Image}
                          alt=""
                          className="h-[530px] object-cover"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {/* {!isLowCarbon && isLowCarbon !== null && (
                        <ReactPlayer
                          controls
                          className={`w-full  object-cover bg-cover`}
                          // preload="none"
                          onPlay={() =>
                            handlePlayVideo(item.fields?.Video?.value?.href)
                          }
                          config={{
                            file: {
                              attributes: {
                                poster:
                                  item.fields?.BackgroundImage?.value?.src,
                              },
                            },
                          }}
                          width="100%"
                          height={
                            item.fields?.Video?.value?.href?.includes("//youtu")
                              ? "630px"
                              : "100%"
                          }
                          url={item.fields?.Video?.value?.href}
                        />
                      )} */}
                      <>
                        <div className="relative w-full min-h-[530px] flex justify-center items-center ">
                          <div
                            onClick={() => setShowModal(true)}
                            className="absolute  flex bg-[rgba(0,0,0,0.4)] items-center justify-center rounded-full border w-14 h-14 cursor-pointer hover:border-brand group border-1 border-white"
                          >
                            <FontAwesomeIcon
                              className=" text-white text-2xl group-hover:text-brand"
                              icon={faPlay}
                            />
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </div>
                <RichText
                  field={item.fields?.Description}
                  className="md:w-1/2 w-full text-bluedark my-4"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* {fields?.SelectMedia?.length > 1 && (
        <div className="relative h-[130px] overflow-hidden">
          <div className="absolute w-full md:start-[64px] start-[10px]">
            <Slider {...thumbsSetting} ref={(el) => (thumbsRef.current = el)}>
              {fields?.SelectMedia.map((item, index) => (
                <div
                  className="px-4 relative"
                  key={`thumbnail-${index}`}
                  dir={isRtl === "true" ? "rtl" : "ltr"}
                >
                  {activeItem === index && (
                    <div className="absolute bg-[#94cd2c]/50 w-[calc(100%_-_32px)] h-full start-4 top-0 z-50" />
                  )}
                  <Image
                    field={
                      item.fields?.Image?.value?.src
                        ? item.fields?.Image
                        : item.fields?.BackgroundImage
                    }
                    alt=""
                    onClick={() => handleThumbnailClick(index)}
                    className="h-[130px] object-cover cursor-pointer"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Default;
