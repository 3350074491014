import React, { useEffect, useRef, useState } from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import ModalFull from "../../../Common/ModalFull";
import parse from "html-react-parser";
import BackgroundVideo from "./BackgroundVideo";
import FullWidth from "./FullWidth";
import { Helmet } from "react-helmet";
import VideoModal from "../../../Common/VideoModal";

const VideoBanner = ({ fields, params }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [lowCarbon, setLowCarbon] = useState(null);

  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");

    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  });
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef(null);
  return (
    <>
      {(params?.Variant === "Default" || !params?.Variant) && (
        <div className="md:container mt-10 mb-10 md:mb-16">
          <Helmet>
            <meta name="image" content={fields?.BackgroundImage?.value?.src} />
            <meta
              name="og:image"
              content={fields?.BackgroundImage?.value?.src}
            />
          </Helmet>
          {showModal && (
            <VideoModal
              video={fields?.Video}
              poster={fields?.BackgroundImage}
              onClose={() => setShowModal(false)}
              isIframe={fields?.IsIframe?.value}
            />
          )}
          <div className="lg:flex flex-wrap">
            <div className="lg:w-7/12 w-full md:mx-0 mx-5 font-[500] text-grey-900   ">
              <div className="video md:pb-0 h-full relative flex items-center justify-center bg-gradient-to-r  from-[#A5AD22] from-5% to-[rgba(0,0,0,0)] to-80% dark:from-[#A5AD22] dark:to-[#7BB1D9]">
                <Image
                  field={fields?.BackgroundImage}
                  className="w-full h-full object-cover dark:opacity-0"
                  alt=""
                />
                <div
                  className="bg-lime-green-900 absolute rounded-full w-20 h-20 border border-dark-green-900 cursor-pointer hover:bg-brand  text-lg md:text-xl p-2 flex justify-center items-center"
                  onClick={() => setShowModal(true)}
                >
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 11L-9.78799e-07 21.3923L-7.02746e-08 0.607695L18 11Z"
                      fill="#163331"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className=" lg:w-5/12 w-full md:px-20 md:mx-0 mx-5 px-5  bg-lime-green-900 py-10">
              <div className="flex w-full h-full items-center">
                <div>
                  <h3 className="text-brand mb-6 lg:text-4xl xl:text-[56px] xl:leading-[55px]">
                    <RichText field={fields?.Title} />
                  </h3>
                  <div className="mb-8 text-lg md:text-xl">
                    <RichText field={fields?.Description} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {params?.Variant === "BackgroundVideo" && (
        <BackgroundVideo fields={fields} params={params} />
      )}
      {params?.Variant === "FullWidth" && (
        <FullWidth
          lowCarbon={lowCarbon}
          isMobile={isMobile}
          fields={fields}
          params={params}
        />
      )}
    </>
  );
};

export default VideoBanner;
