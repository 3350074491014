import React, { useEffect, useRef, useState } from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from "react-slick";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick-theme.css";
import parse from "html-react-parser";
import "./stlye.css";
import CTA from "../../../Common/CTA";
import VideoModal from "../../../Common/VideoModal";
import TranslatedWord from "../../../Common/DictionaryTexts";
const CarouselBanner = ({ fields, params, isRtl }) => {
  const sliderRef = useRef(null);
  const [isModelOn, setIsModuleOn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [video, setVideo] = useState({});
  const [lowCarbon, setLowCarbon] = useState(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
    console.log(sliderRef.current, "sliderRef.current");
    console.log(isRtl, "context. rtl");
    console.log(typeof isRtl, "context. typeof");
  }, []);

  const settings = {
    appendDots: (dots) => {
      return (
        <div dir={isRtl === "true" ? "rtl" : "ltr"}>
          <ul className="absolute end-10 md:end-20 bottom-14 rtl:flex rtl:flex-row-reverse rtl:ms-0">
            {dots}
          </ul>
        </div>
      );
    },
    customPaging: function (i) {
      return (
        <span
          className={`h-px bg-white w-8 md:w-16 inline-block me-2 relative ${
            lowCarbon ? "hidden" : ""
          }`}
        >
          <span
            className="h-0.5 absolute bottom-0 start-0 bg-white w-0"
            style={{ animationDuration: `${params.speed}ms` }}
          ></span>
        </span>
      );
    },
    dots: true,
    infinite: true,
    autoplay: true,
    fade: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplaySpeed: parseInt(params.speed) || 7000,
    rtl: isRtl === "true" ? true : false,
  };

  return (
    <>
      <section
        className={`color-block relative carouselBanner ${
          !lowCarbon && lowCarbon !== null ? "h-screen md:h-[700px]" : ""
        }`}
      >
        {isModelOn && (
          <VideoModal
            video={video?.fields?.Video}
            poster={
              isMobile && video?.fields?.BackgroundMobileImage?.value?.src
                ? video?.fields?.BackgroundMobileImage
                : video?.fields?.BackgroundImage
            }
            onClose={() => setIsModuleOn(false)}
            isMobile={isMobile}
            isIframe={fields?.IsIframe?.value}
          />
        )}

        <Slider {...settings} ref={sliderRef} className="overflow-hidden">
          {fields?.CarouselBanners?.map((slide, index) => (
            <div
              key={index}
              className={`relative ${
                !lowCarbon && lowCarbon !== null ? "h-screen md:h-[700px]" : ""
              }`}
              dir={isRtl === "true" ? "rtl" : "ltr"}
            >
              {!lowCarbon && lowCarbon !== null && (
                <div
                  style={{
                    background: `url(${
                      isMobile &&
                      slide?.fields?.BackgroundMobileImage?.value?.src
                        ? slide?.fields?.BackgroundMobileImage?.value?.src
                        : slide?.fields?.BackgroundImage?.value?.src
                    })  center\/ cover`,
                  }}
                  className="carousel-banner-slide  bg-cover absolute top-0 start-0 w-full  h-full "
                />
              )}
              <div className=" absolute top-0 start-0 w-full  h-full dark:bg-green-gradient hidden dark:block z-0" />
              <div className="container pt-32 md:pt-48 pb-20 relative h-full">
                {parse(slide.fields?.Title?.value)}
                <div className="my-4">
                  {parse(slide.fields?.Description?.value)}
                </div>
                <div className="pt-4">
                  <div className="flex gap-4 flex-wrap w-full">
                    {slide.fields?.Video?.value?.href && (
                      <CTA
                        onClick={() => {
                          setIsModuleOn(true);
                          setVideo(slide);
                        }}
                        className="bottom-12 md:bottom-6 !absolute"
                      >
                        <TranslatedWord EnWord="Play Video" />
                      </CTA>
                    )}
                    {slide.fields?.CTAButtonList?.map((cta, index) => {
                      return (
                        <a key={index} href={cta.fields?.CTALink?.value?.href}>
                          <CTA
                            className={`${index === 1 && "bg-white"}`}
                            external={
                              cta.fields?.CTALink?.value?.linktype ===
                              "external"
                            }
                          >
                            {cta.fields?.CTALink?.value?.text}
                          </CTA>
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default CarouselBanner;
