import React, { useEffect, useRef, useState } from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import arrowLeft from "../../../../assets/icons/arrow-left-dark.svg";
import arrowRight from "../../../../assets/icons/arrow-right-dark.svg";
import Collapser from "../../../Common/Collapser";
import CTA from "../../../Common/CTA";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper";

const Events = ({ fields, isRtl }) => {
  const [hideNav, setHideNav] = useState(false);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const [lowCarbon, setLowCarbon] = useState(null);

  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
    if (
      window.innerWidth >= 1280 &&
      fields?.data?.GQLResult?.results?.items?.length <= 3
    ) {
      setHideNav(true);
    }
    console.log(isRtl, "context. rtl");
    console.log(typeof isRtl, "context. typeof");
  });

  const toNext = () => {
    // sliderRef && sliderRef.current.slickNext();
  };

  const toPrev = () => {
    // sliderRef && sliderRef.current.slickPrev();
  };

  return (
    <section>
      <div className="container py-10 ">
        <div className="flex flex-row justify-between items-center flex-wrap lg:flex-none">
          <h3 className="text-bluedark font-[500] inline">
            <RichText field={fields?.data?.GQLSource?.title} />
          </h3>
          {!hideNav && (
            <div className=" md:w-fit inline-flex rtl:flex-row-reverse w-fit mt-8 lg:mt-0 items-center md:justify-end justify-between gap-6">
              <button ref={prevRef}>
                <img src={arrowLeft} alt="arrow-prev" />
              </button>
              <button ref={nextRef}>
                <img src={arrowRight} alt="arrow-next" />
              </button>
            </div>
          )}
        </div>

        <div className="mt-10">
          <Swiper
            ref={swiperRef}
            onInit={(s) => setSwiper(s)}
            className="swiper-qual-slides-height"
            dir={isRtl === "true" ? "rtl" : "ltr"}
            autoHeight={false}
            modules={[Navigation]}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            {fields?.data?.GQLResult?.results?.items?.map((item, index) => (
              <SwiperSlide
                key={index}
                dir={isRtl === "true" ? "rtl" : "ltr"}
                className="h-auto"
              >
                <div className="px-4 flex h-full flex-col justify-between">
                  <div>
                    <div
                      className={` ${lowCarbon ? "bg-green-gradient" : ""} ${
                        item?.item?.Image?.src ? "h-[326px]" : "h-0"
                      }`}
                    >
                      {!lowCarbon && (
                        <Image
                          field={item?.item?.Image}
                          className="w-full relative h-full object-cover"
                        />
                      )}
                    </div>
                    <div className="flex gap-2 absolute top-0 mt-2 ms-2">
                      {item?.item?.StartDate?.rendered && (
                        <div className="text-tumeric-300  bg-tumeric-100 px-3 py-2  rounded-full text-base font-medium">
                          {new Date(
                            item?.item?.StartDate?.formattedDateValue
                          ).getDate()}{" "}
                          {new Date(
                            item?.item?.StartDate?.formattedDateValue
                          ).toLocaleString("default", { month: "short" })}
                        </div>
                      )}
                      {item?.item?.EndDate?.rendered && (
                        <div className="text-tumeric-300  bg-tumeric-100 px-3 py-2  rounded-full text-base font-medium">
                          {new Date(
                            item?.item?.EndDate?.formattedDateValue
                          ).getDate()}{" "}
                          {new Date(
                            item?.item?.EndDate?.formattedDateValue
                          ).toLocaleString("default", { month: "short" })}
                        </div>
                      )}
                    </div>
                    <h5 className="text-brand font-[500] mt-4">
                      <RichText field={item?.item?.Title} />
                    </h5>
                    <div className="mt-4 font-base">
                      <RichText field={item?.item?.Description} />
                    </div>
                    {item?.item?.AddtionalInformation?.value && (
                      <Collapser>
                        <div className={`text-bluedark text-base`}>
                          <RichText field={item?.item?.AddtionalInformation} />
                        </div>
                      </Collapser>
                    )}
                  </div>
                  {item?.item?.CTA?.url && (
                    <a
                      href={item?.item?.CTA?.url}
                      target={
                        item?.item?.CTA?.linktype === "external"
                          ? "_blank"
                          : "_self"
                      }
                      rel="noreferrer"
                    >
                      <CTA
                        color="brand"
                        className="flex w-full  justify-center items-center mt-6 "
                        external={item?.item?.CTA?.linktype === "external"}
                      >
                        {item.item?.CTA?.text}
                      </CTA>
                    </a>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Events;
